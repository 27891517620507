.btn-secondary:hover {
  background: #e2ac07 !important;
  border-color: #e2ac07 !important;
  color: #0f203a !important;
}
.login-layout h2 {
  text-align: left;
}
.login-layout h2 {
  color: #1e71ff;
}
.rt-table {
  text-align: center;
}
a,
a:hover,
a:focus {
  text-decoration: none !important;
}
.modal-title {
  float: left;
  width: 100%;
}
.rt-resizable-header-content {
  font-weight: 500;
}
.srv-validation-message {
  font-size: 12px;
}
p.srv-validation-message {
  color: #ff0000 !important;
  font-size: 12px !important;
  font-weight: 500;
}
.sidebar-background {
  background-image: none !important;
  background-color: #0a3473;
}
.app-sidebar:after,
.off-canvas-sidebar:after {
  z-index: 3;
  opacity: 1;
  background-color: #0f203a !important;
}
button:hover,
button:focus {
  outline: none !important;
  box-shadow: none;
}
.btn-secondary {
  color: #fff !important;
  background-color: #0f203a !important; /* border-color: #868e96 !important; */
}
.main-layout {
  padding: 6px 16px;
}
.main-layout main {
  color: #0f203a !important;
  padding-bottom: 24px;
}
.main .text-muted {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-wrap: wrap;
}
.user-table .form-control {
  opacity: 1;
  color: #333;
}
.category-table .form-control {
  opacity: 1;
  color: #333;
}
.skills-table .form-control {
  opacity: 1;
  color: #333;
}
.dropdown-menu {
  min-width: 9rem;
  min-height: 0.9rem;
  padding: 0rem;
  margin: 0rem;
}
.form label {
  color: #75787d;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
}
.app-sidebar .logo-img,
.off-canvas-sidebar .logo-img {
  margin-left: -7px;
}



/* login form */
.login-layout {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.login-form {
  float: left;
  width: 480px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.login-form h3 {
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  color: #333;
}
.login-form p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
}
.login-layout .login-form .form-group {
  margin-bottom: 16px;
}
.login-layout .login-form .form-group label {
  margin-bottom: 2px;
}
.login-form .forgot-pass {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 14px;
  padding-top: 20px;
  color: #0f203a;
  font-weight: 500;
}
.login-form .form-area {
  padding: 25px 40px;
  float: left;
  width: 100%;
}
.login-form .form-area h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.login-bg {
  background: url(../img/loginbg.png) no-repeat left top;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  width: 100%;
  padding: 30px;
  text-align: center;
  background-size: 100%;
}
.login-bg img {
  max-width: 54px;
}
.login-bg h2 {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 32px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  padding: 12px 0px 0px;
  margin: 0px;
}
.form-control {
  font-size: 12px !important;
  border-radius: 12px;
  height: calc(2em + 0.75rem + 2px);
}
.btn {
  border-radius: 12px;
  padding: 8px 21px 8px !important;
}
.form-area .btn {
  margin-top: 5px !important;
}
.login-layout {
  background: url(../img/login-main-bg.png) no-repeat center center;
  background-size: 100% 100%;
}

/* navbar */
.layout-light .navbar {
  background: #ffffff;
  padding: 4px 16px;
  margin: 10px 0px 30px;
  border-radius: 8px;
}
.navbar-header .navbar-form {
  margin-top: 0px !important;
}
.app-sidebar .navigation li > a:before,
.off-canvas-sidebar .navigation li > a:before {
  border-color: #e2ac07;
}
.navbar-container .navbar-collapse .dropdown .dropdown-divider {
  margin: 0px;
}
.add-new-form .form-control {
  width: 200px;
}
.add-new-form label {
  font-weight: 400;
  font-size: 14px;
}
.app-sidebar .navigation .menu-item-text,
.off-canvas-sidebar .navigation .menu-item-text {
  font-weight: 400 !important;
}
.app-sidebar .navigation i {
  line-height: 28px;
}

/* table */
.ReactTable {
  background: #fff;
  border-radius: 6px;
}
.ReactTable .rt-thead {
  overflow-y: auto;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-td {
  padding: 12px !important;
  font-size: 14px;
  font-weight: 400;
}
.ReactTable .rt-td label {
  margin-bottom: 0px;
}
.ReactTable .rt-td label .react-toggle {
  vertical-align: middle;
}
.ReactTable .rt-th,
.ReactTable .rt-td {
  font-size: 14px;
  font-weight: 400;
}
.ReactTable .rt-td {
  color: #666666;
}
.ReactTable .rt-td p {
  margin-bottom: 0px;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #e2ac07;
}
.form-control:focus {
  border-color: #0f203a !important;
  box-shadow: 0 0 0 0.2rem rgba(15, 32, 58, 0.2) !important;
}
.modal .modal-body p {
  font-size: 20px;
  color: #333;
  font-weight: 500;
}
.modal .modal-title .close {
  width: 34px;
  height: 34px;
  padding: 0px;
  position: absolute;
  opacity: 1;
  right: 10px;
  top: 10px;
  line-height: 16px;
}
.modal .modal-title .close:hover {
  opacity: 1;
}

/* modal */
.modal-header > .close {
  display: none;
}
.modal .modal-title {
  font-weight: 400;
}
.modal .modal-title .close {
  transition: 0.5s;
  background: #0f203a;
  text-shadow: none;
  color: #ffffff;
  font-weight: 300;
  font-size: 32px;
  border-radius: 8px;
}
.modal .modal-title .close span {
  display: inline-block;
  vertical-align: top;
  line-height: 34px;
}
.modal .modal-title .close:hover {
  right: 12px;
  top: 12px;
}
.modal .modal-body {
  padding: 20px 24px;
}
.modal-dialog {
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.user-modal {
  float: left;
  width: 100%;
  text-align: left;
}
.user-modal label {
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-weight: 500;
}
.user-modal .form-control {
  font-size: 13px !important;
  border-radius: 17px;
  height: calc(2em + 0.75rem + 2px);
  overflow: hidden;
}
.user-modal .react-tagsinput {
  font-size: 13px !important;
  border-radius: 17px;
  min-height: calc(2em + 0.75rem + 2px);
  padding: 3px 15px;
}
.user-modal .react-tagsinput input {
  margin: 2px 0px;
  padding: 3px 0px 0px;
}
.user-modal .react-tagsinput > span {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.user-modal .react-tagsinput .react-tagsinput-tag {
  background-color: rgba(15, 32, 58, 0.5);
  color: #fff;
  border: 1px solid rgba(15, 32, 58, 0.7);
  order: 2;
  margin: 2px 1px;
  padding: 3px 8px;
  border-radius: 8px;
  height: auto;
  vertical-align: top;
  line-height: 19px;
  float: left;
}
.user-modal .react-tagsinput .react-autosuggest__container {
  order: 1;
}
.user-modal .react-tagsinput--focused {
  border-color: #0f203a;
}
.file-upload {
  width: 300px !important;
  height: auto !important;
  border: 1px dashed rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  padding: 10px;
  background: #f5f5f5;
}
.modal .modal-body .file-upload p {
  font-size: 15px;
  margin: 0px;
}
.file-upload .btn {
  position: absolute;
  background-color: rgba(15, 32, 58, 0.5);
  right: 0px;
  top: 0px;
  height: 100%;
  border-radius: 0px 5px 5px 0px;
  margin: 0px;
  padding: 10px 15px;
  color: #e2ac07 !important;
}
.file-upload .btn svg {
  width: 24px;
  height: 24px;
}
.filename {
  float: left;
  width: 100%;
}
.filename span {
  float: left;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
.modal-content {
  border: 0px;
}

/* table pagination */
.main-layout .ReactTable .-pagination {
  align-items: center;
}
.main-layout .ReactTable .-pagination .-center {
  justify-content: center;
}
.main-layout .ReactTable .-pagination .-btn {
  padding: 8px 10px;
  min-width: 120px !important;
  width: auto !important;
  border-radius: 12px;
  color: #fff;
  background-color: #0f203a;
}
.main-layout .ReactTable .-pagination .-btn:hover {
  background: #e2ac07 !important;
  color: #0f203a !important;
}
.main-layout .ReactTable .-next .-btn {
  float: right;
}
.main-layout .ReactTable .-pageInfo {
  font-weight: 500;
  font-size: 14px;
  color: #666666;
  margin: 0px !important;
}
.main-layout .ReactTable .-pagination .-pageJump input {
  text-align: left;
  padding: 2px 0px 2px 10px;
  line-height: 20px;
  font-size: 13px;
  max-width: 45px;
  margin: 0px 5px;
}
.ReactTable .-pagination .select-wrap {
  margin: 0px 6px 0px 10px !important;
}
.ReactTable .-pagination select {
  padding: 4px 0px 3px 10px !important;
  line-height: 20px !important;
  font-size: 13px !important;
}
.react-toggle--checked .react-toggle-thumb {
  border-color: #0cc27e !important;
}
.react-toggle--checked .react-toggle-track {
  background: #0cc27e !important;
}
.reset-pass {
  width: 100%;
}
.card-title-top {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}

/* user details */
.user-details {
  float: left;
  width: 100%;
}
.user-details h2 {
  float: left;
  width: 100%;
}
.user-details .form-group {
  float: left;
  width: 100%;
}
.user-details .form-group label {
  color: #0f203a;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  margin-bottom: 0px;
  float: left;
  width: 100%;
}
.user-details .form-group .info-control {
  min-height: 48px;
  border-bottom: 2px solid #f2f2f2;
  float: left;
  width: 100%;
  padding: 10px 5px;
  font-size: 15px;
  font-weight: 400;
  color: #666;
}
.user-details .task-imag-area {
  display: flex;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.user-details .task-image {
  width: 250px;
  border-radius: 20px;
  height: 250px;
  float: left;
  margin-right: 16px;
  padding: 8px;
  border: 2px solid #f2f2f2;
}
.user-details .task-image img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.user-details .profile-image {
  width: 100px;
  border-radius: 20px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 2px solid #e5e5e5;
}
.user-details .profile-image img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.in-active {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #0cc27e;
  float: left;
  margin-right: 8px;
  margin-top: 5px;
}
.in-active.gray {
  background: #0f203a;
  opacity: 0.2;
}
.selected-date-range-btn span {
  font-size: 13px;
}
.daterangepicker .ranges li {
  padding: 7px 12px;
}
.daterangepicker .ranges li.active {
  background-color: #0f203a;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #0f203a;
  border-radius: 5px;
}
.drp-buttons .btn {
  margin-bottom: 0px !important;
}
.drp-buttons .btn-primary {
  background-color: #0f203a;
  border-color: #0f203a;
}
.drp-buttons .btn-primary.disabled,
.drp-buttons .btn-primary:disabled {
  background-color: #0f203a;
  border-color: #0f203a;
}
.drp-buttons .cancelBtn {
  background-color: #ff586b;
  border-color: #ff586b;
  color: #fff;
}
.card-title-top.inner-title {
  font-weight: 500;
  color: #666;
}
.inner-title-top {
  border-top: 1px dashed #f2f2f2;
  padding: 20px 0px 10px 0px;
}
.card-body .inner-title-top:first-child,
.card-body .inner-title-top:nth-child(2) {
  padding-top: 0px;
  border-top: 0px;
}

/* ReactTable */
.ReactTable .pagination-bottom {
  order: 3;
}
.ReactTable .rt-noData {
  order: 2;
  position: static !important;
  transform: none !important;
  text-align: center;
  padding: 40px 10px;
  font-weight: 500;
  z-index: 0 !important;
}
.select-map {
  max-height: 155px;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px 0px;
  border: 1px solid #f2f2f2;
  margin: 8px 0px 0px;
  border-radius: 6px;
}
.select-map .col-sm-6 {
  padding: 10px 15px;
}
.select-map .col-sm-6 span {
  font-weight: 400;
  text-transform: capitalize;
  display: flex;
  align-items: flex-start;
  line-height: 20px;
}
.select-map .col-sm-6 span img {
  margin-right: 10px;
  margin-top: 2px;
}
.form-control.select-marker {
  line-height: 27px;
}
.form-control.select-marker span {
  margin-left: 16px;
  display: inline-block;
  vertical-align: top;
  margin-top: -2px;
  text-transform: capitalize;
}
.form-control.select-marker span img {
  width: 14px;
  margin-right: 4px;
}
.add-new-form {
  margin-top: 24px;
}
.add-new-form label.date-label {
  width: 100px;
}
.add-new-form .form-control.task-select {
  width: 100px;
}
.comment-block {
  float: left;
  width: 100%;
}
.comment-block > ul {
  border-bottom: 1px solid #f2f2f2;
}
.comment-block ul {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
}
.comment-block ul li {
  float: left;
  list-style: none;
  width: 100%;
  border-top: 1px solid #f2f2f2;
  padding: 16px 0px;
}
.comment-block ul li:first-child {
  border-top: 0px;
}
.comment-block ul li .comment-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.comment-block ul li .comment-content img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 24px;
}
.comment-block ul li .comment-content h3 {
  float: left;
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
}
.comment-block ul li .comment-content span {
  margin-left: auto;
  float: right;
  font-size: 14px;
  font-weight: 500;
  color: #666;
}
.comment-block ul li p {
  float: left;
  width: 100%;
  margin: 16px 0px 8px;
  font-size: 14px;
  line-height: 24px;
}
.comment-block ul li .replay {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 16px;
}
.comment-block ul li .replay span {
  float: right;
  font-size: 14px;
  font-weight: 500;
  color: #666;
}
.comment-block ul li .replay button {
  border: 0;
  padding: 0;
  border: 0;
  color: rgb(226, 172, 7);
  background: transparent;
  line-height: 19px;
}
.comment-block ul ul {
  width: 80%;
  float: right;
}
.comment-area {
  float: left;
  width: 100%;
  padding: 16px;
  border: 1px solid #eee;
  color: #666666;
}
.info-control-profile {
  float: left;
  margin-right: 24px;
  margin-top: 16px;
}
/* Common Table */
.ReactTable .rt-thead,
.ReactTable .rt-tbody {
  overflow: hidden !important;
}
.ReactTable .rt-th,
.ReactTable .rt-td {
  text-align: left;
}

/* Tracking  */
.card-title-main {
  float: left;
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  color: #0f203a;
}

/* New Css */
.select-type label {
  margin-bottom: 0px;
}
.select-type .select__control {
  border-radius: 12px !important;
  height: calc(2em + 0.75rem + 2px);
  font-size: 12px;
  color: #333;
}
.total-no {
  background-color: #0f203a;
  color: #e2ac07 !important;
  padding: 4px 8px;
  font-size: 16px;
  border-radius: 12px;
  float: left;
  height: 38px;
  margin: 24px 0px 0px;
  line-height: 30px;
  width: 58px;
  text-align: center;
}

.check-table .rt-th {
  text-align: center;
}
.user-tab-content {
  margin-bottom: 24px;
  border-radius: 0px 0px 6px 6px;
  float: left;
  width: 100%;
  background: rgba(200, 200, 200, 0.3);
  border-radius: 0px 0px 0px 0px;
}
.user-tab {
  background: rgba(200, 200, 200, 0.3);
  border-radius: 6px 6px 0px 0px;
  border-bottom: 2px solid rgba(200, 200, 200, 0.5);
}
.user-tab .nav-item a {
  border: 0px;
  color: #333;
  font-weight: 400;
}
.user-tab .nav-item a.nav-link.active {
  color: #0f203a;
  font-weight: 500;
}
.user-details .card {
  box-shadow: none;
}
.rotate-icon {
  position: absolute;
  right: -14px;
  top: -12px;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  padding: 2px;
}
.rotate-icon svg {
  margin-right: 0px !important;
}
.info-control-profile {
  position: relative;
}

.inner-tab .nav-item {
  line-height: 20px;
}
.inner-tab .nav-item a {
  font-size: 15px;
  font-weight: 400;
}
.inner-tab .nav-item.active {
  background-color: #0f203a !important;
}
.nav-pills.inner-tab .nav-link.active,
.nav-pills.inner-tab .show > .nav-link {
  background-color: #0f203a !important;
}
.user-tab-content .tab-pane .add-new-form {
  margin-top: 0px;
}

/* Comment Section */
.comment-block {
  float: left;
  width: 100%;
}
.comments-sec.card-body {
  padding-right: 0px;
  padding-left: 0px;
}
.comment-block ul {
  float: left;
  width: 100%;
  border: 0px;
}
.comment-block ul li {
  float: left;
  width: 100%;
  border: 0px;
  padding: 8px 0px;
}
.comment-block ul li .left-chat > div {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.comment-block ul li .left-chat .text-area {
  background-color: #f2f2f2;
  float: left;
  width: 70%;
  border-radius: 0px 10px 10px 10px;
  padding: 12px;
  position: relative;
}
.comment-block ul li .text-area .edit-option {
  position: absolute;
  right: 8px;
  top: 8px;
}
.comment-block ul li .text-area .edit-option .badge.badge-danger {
  font-size: 12px;
  font-weight: 400;
}
.comment-block ul li .text-area .edit-option i {
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: top;
  border-radius: 100%;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
.comment-block ul li .text-area .edit-option i svg {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px !important;
}
.comment-block ul li .text-area .edit-option i.delete {
  background-color: #ff586b;
}
.comment-block ul li .text-area .edit-option i.edit {
  background-color: #0f203a;
  margin-right: 8px;
}
.comment-block ul li .text-area h3 {
  float: left;
  width: 100%;
  margin: 0px 0px 6px;
  font-size: 16px;
  font-weight: 600;
  padding-right: 80px;
}
.comment-block ul li .text-area p {
  color: #333;
  font-size: 14px;
  margin: 0px;
  float: left;
  width: 100%;
  font-weight: 400;
  padding-right: 80px;
}
.comment-block ul li .text-area h3 a {
  color: #0f203a;
}
.comment-block ul li .date-edit {
  float: left;
  width: 30%;
  text-align: right;
}
.comment-block ul li .date-edit span {
  font-size: 14px;
  color: #999;
}
.delete-by-user {
  position: absolute;
  right: 16px;
  bottom: 28px;
}

/* Right Chat */
.comment-block ul li .right-chat .text-area {
  float: right;
  width: 70%;
  background-color: #e2ac07;
  border-radius: 10px 10px 0px 10px;
  padding: 12px;
  position: relative;
  order: 2;
}
.comment-block ul li .right-chat > div {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.comment-block ul li .right-chat .date-edit {
  text-align: left;
}
.comment-block ul li .right-chat .text-area h3 {
  padding-right: 0px;
  padding-left: 80px;
  color: #fff;
}
.comment-block ul li .right-chat .text-area img {
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 4px;
  margin-top: 8px;
}
.comment-block ul li .right-chat .text-area h3 a {
  color: #fff;
}
.comment-block ul li .right-chat .text-area p {
  color: rgba(255, 255, 255, 0.8);
  padding-right: 0px;
  padding-left: 80px;
}
.comment-block ul li .right-chat .text-area .edit-option {
  left: 8px;
  right: auto;
}

/* Center chat */
.comment-block ul li .center-chat > div {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.comment-block ul li .center-chat .text-area {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 35%);
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 12px;
  position: relative;
}
.comment-block ul li .center-chat .text-area img {
  border-radius: 16px;
  background-color: #ffffff;
  padding: 4px;
  float: left;
}
.comment-block ul li .center-chat .date-edit {
  width: calc(100% - 35%);
}

.overlap-select.basic-single {
  z-index: 10;
}
.basic-single {
  background: #ffffff !important;
  z-index: 9;
  border-radius: 12px;
}
.export-btn {
  width: 100%;
}
.multi-col-list {
  flex-wrap: wrap;
  flex-direction: row;
}
.multi-col-list > span {
  width: 20%;
  min-width: 300px;
}
.multi-col-list > span .list-group-item {
  height: 100%;
}
.multi-col-list-max-height {
  overflow-y: auto;
  max-height: 200px;
}
/* responsive */
@media only screen and (max-width: 992px) {
  .navbar-header {
    width: auto !important;
  }
  .navbar-header .navbar-toggle {
    margin: 0px;
  }
  .navbar-header svg {
    padding: 0px;
    width: 22px;
    height: 22px;
    margin: 0px !important;
  }
  .layout-light .navbar {
    padding: 8px 16px;
  }
  .navbar-header .navbar-toggle {
    width: 26px;
    height: 24px;
  }
  .modal-open .modal {
    padding: 15px;
  }
  .comment-block ul ul {
    width: 95%;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-container {
    width: auto;
    padding: 0px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0px;
  }
  .body-hidden {
    overflow: hidden;
  }
  .body-hidden:after {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    top: 0;
    left: 0;
    z-index: 4;
  }
}
@media only screen and (max-width: 767px) {
  .main-layout .ReactTable .-pageInfo {
    margin: 10px 0px !important;
  }
  .user-details .task-imag-area {
    flex-wrap: wrap;
  }
  .user-details .task-image {
    width: calc(50% - 16px);
    margin: 0px 8px 16px;
  }
  .date-calender .react-bootstrap-daterangepicker-container {
    width: 100%;
  }
  .add-new-form .total-no.mt-3 {
    margin-top: 0px !important;
  }
  .add-new-form .export-btn.mt-3 {
    margin-top: 0px !important;
  }
  /* Comment Chat */
  .comment-block ul li .text-area {
    padding-top: 48px !important;
  }
  .comment-block ul li .text-area h3 {
    padding-left: 0px;
  }
  .comment-block ul li .date-edit {
    width: 100%;
    text-align: left;
  }
  .comment-block ul li .left-chat .text-area {
    width: 100%;
    border-radius: 10px;
  }
  .comment-block ul li .right-chat .text-area {
    width: 100%;
    order: 1;
    border-radius: 10px;
  }
  .comment-block ul li .right-chat .text-area p {
    padding-left: 0px;
  }
  .comment-block ul li .right-chat .text-area h3 {
    padding-left: 0px;
  }
  .comment-block ul li .right-chat .date-edit {
    width: 100%;
    text-align: right;
    order: 2;
  }
  .comment-block ul li .center-chat .text-area {
    width: 100%;
    border-radius: 10px;
  }
  .comment-block ul li .center-chat .date-edit {
    width: 100%;
  }
  .sent-area {
    border-right: none !important;
  }
  .user-details button {
    width: 100%;
    margin: 5px 0 !important;
  }
}
@media only screen and (max-width: 480px) {
  .login-layout {
    padding: 0px 15px;
  }
  .login-layout .main {
    width: 100%;
  }
  .login-form .form-area {
    padding: 15px 0px;
  }
  .login-bg {
    padding: 20px;
  }
  .login-bg img {
    max-width: 40px;
  }
  .login-bg h2 {
    font-size: 22px;
  }
  .login-form {
    width: 100%;
  }
  .-previous,
  .-next {
    flex: 100% !important;
    width: 100% !important;
  }
  .main-layout .ReactTable .-pagination .-btn {
    min-width: 100% !important;
    width: 100% !important;
  }
  .modal .modal-body {
    padding: 15px;
  }
  .file-upload {
    width: 100% !important;
  }
  .user-details .task-image {
    width: 100%;
    margin: 0px 0px 8px;
  }
  .task-status .form-control {
    width: 140px;
    margin-right: 0px !important;
  }
  .comment-block ul li .comment-content img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
  .comment-block ul li .comment-content h3 {
    font-size: 16px;
  }
  .comments-sec {
    padding: 15px;
  }
  .navbar-nav > li:last-child {
    float: none;
  }
}
/* .Modal-data-center{
    display: flex;
    justify-content: center;
} */
.modal-header h5 {
  font-weight: 700 !important;
  font-size: 26px;
}
.modal-body .modal-head {
  margin-bottom: 20px;
  font-size: 20px;
  color: #ff586b;
}
.modal-body .modal-head-2 {
  font-size: 20px;
  color: #ff586b;
  margin: 20px 0px;
}
.cancle-modal .col {
  margin: 5px 0px;
}
.modal-body hr {
  margin: 0.5rem 0rem !important;
}
.cancle-modal img {
  height: 50px;
  width: 50px;
  border-radius: 34px;
  margin: 0px 15px;
}
.user_name {
  position: relative;
  left: 29%;
  top: -10px;
  width: 50%;
}
.user_name_2 {
  position: relative;
  top: -15px;
  left: 34%;
}
.check_box {
  margin-left: 20px;
}
.cancle-modal input {
  height: 15px;
  width: 15px;
}
.user-modal.user-edit-modal .react-datepicker-wrapper {
  width: 100%;
}
.user-modal.user-edit-modal .select__control {
  border-radius: 17px;
}
.user-modal .select__control {
  border-radius: 17px;
}
.check-multiple-item .rt-tr .rt-th:first-child {
  padding: 15px 0px 0px 2px !important;
}
.check-multiple-item .rt-tr .rt-td:first-child {
  padding: 15px 0px 0px 2px !important;
}
.form-control {
  border-radius: 12px !important;
}
.selected-date-range-btn {
  border-radius: 12px;
}

.modal-header h5 {
  font-weight: 600 !important;
  font-size: 20px;
  color: #0f203a;
}

.modal-body .modal-head {
  margin-bottom: 20px;
  font-size: 20px;
  color: #ff586b;
}

.modal-body .modal-head-2 {
  font-size: 20px;
  color: #ff586b;
  margin: 20px 0px;
}

.cancle-modal .col {
  margin: 5px 0px;
}

.modal-body hr {
  margin: 0.5rem 0rem !important;
}

.cancle-modal img {
  height: 50px;
  width: 50px;
  border-radius: 34px;
  margin: 0px 15px;
}

.user_name {
  position: relative;
  left: 29%;
  top: -10px;
  width: 50%;
}

.user_name_2 {
  position: relative;
  top: -15px;
  left: 34%;
}

.check_box {
  margin-left: 20px;
}

.cancle-modal input {
  height: 15px;
  width: 15px;
}
.user-modal.user-edit-modal .react-datepicker-wrapper {
  width: 100%;
}
.user-modal.user-edit-modal .select__control {
  border-radius: 17px;
}
.check-multiple-item .rt-tr .rt-th:first-child {
  padding: 15px 0px 0px 2px !important;
}
.check-multiple-item .rt-tr .rt-td:first-child {
  padding: 15px 0px 0px 2px !important;
}
.user-status .rt-tr .rt-td:last-child {
  width: 190px !important;
}
.user-status .rt-tr .rt-th:last-child {
  width: 190px !important;
}
.form-control {
  border-radius: 12px !important;
}

.selected-date-range-btn {
  border-radius: 12px;
}

.center-input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-image {
  border: none;
  border-radius: 10px;
  padding: 0;
}
.main-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-loader .loaderBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
}

.certificate-img {
  float: left;
  width: 100%;
}
.certificate-img .form-group {
  width: auto;
}

.task-modal-area h3 {
  float: left;
  width: 100%;
  font-weight: 600;
  margin: 20px 0;
  font-size: 20px;
  color: #ff586b;
}
.task-modal-area table {
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  float: left;
  border-collapse: inherit;
}
.task-modal-area table td {
  border-bottom: 1px solid #f2f2f2;
  padding: 6px 16px;
  font-weight: 400;
  font-size: 14px;
}
.task-modal-area table tr td:last-child {
  border-left: 1px solid #f2f2f2;
  font-weight: 500;
}
.task-modal-area table tr:last-child td {
  border-bottom: 0px;
}
.task-modal-area .check-label {
  float: left;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  font-size: 15px;
  margin-right: 24px;
  color: #666;
  font-weight: 400;
}
.task-modal-area .check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.task-modal-area .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border: 2px solid #0a3473;
  border-radius: 50%;
}
.task-modal-area .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.task-modal-area .check-label input:checked ~ .checkmark:after {
  display: block;
}
.task-modal-area .check-label .checkmark:after {
  top: 3px;
  left: 3px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #0a3473;
}
.sent-area {
  float: left;
  width: 100%;
  border-right: 1px solid #e2e2e2;
  padding-right: 16px;
}
.money-sent {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
}
.money-sent h4 {
  float: left;
  font-size: 16px;
  color: #333333;
  font-weight: 500;
  margin: 10px 0;
  width: 70%;
}
.money-sent h4 span {
  display: block;
  font-size: 15px;
  font-weight: 600;
  float: left;
  width: 100%;
  left: 0 !important;
  margin: 14px 0 0;
}
.money-sent .check-label {
  padding-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}
.money-sent .checkmark {
  position: static;
  float: left;
  margin: 0px;
}
.money-sent .check-label .checkmark:after {
  top: 5px;
  left: 5px;
}
.modal .modal-body .sent-area p {
  float: left;
  width: 100%;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  margin: 8px 0px 0px;
}
.sent-area.sent-area-left {
  border-right: 0px;
  padding-right: 0px;
}
.tasktable {
  float: left;
  width: 100%;
}
.task-modal-area .tasktable table {
  border: none !important;
}
.task-modal-area .tasktable table td {
  border: none !important;
  padding: 6px 0;
  font-weight: 500;
}
.money-sent .check-label {
  padding-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}
.user-modal .check-label {
  float: left;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  font-size: 15px;
  margin-right: 24px;
  color: #666;
  font-weight: 400;
  width: auto;
}
.user-modal .check-label input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.user-modal .checkmark {
  position: static;
  float: left;
  margin: 0px;
}
.user-modal .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border: 2px solid #0a3473;
  border-radius: 50%;
}
.user-modal .check-label input:checked ~ .checkmark::after {
  display: block;
}
.money-sent .check-label .checkmark::after {
  top: 5px;
  left: 5px;
}
.user-modal .check-label .checkmark::after {
  top: 3px;
  left: 3px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #0a3473;
}
.user-modal .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
.sent-area span {
  float: left;
  width: 100%;
}
.user-modal .tasktable table td {
  border: none !important;
  padding: 6px 0;
  font-weight: 500;
  font-size: 14px;
}
.user-modal table {
  width: 100%;
  border: none;
  border-radius: 10px;
  float: left;
  border-collapse: inherit;
}
.ReactTable .rt-table {
  z-index: 1;
}
.user-modal.user-notes .col {
  display: flex;
}
.user-modal.user-notes label {
  width: 84px;
  float: left;
}
.user-modal.user-notes span {
  font-size: 14px;
  color: #495057;
  font-weight: 400;
  text-transform: capitalize;
  width: calc(100% - 84px);
}
.select-type .max-height .select__control {
  height: auto !important;
}
.add-new-form .rt-td p {
  font-size: 0.9rem;
}
.btn {
  border-radius: 12px;
  padding: 7px 16px 5px;
  font-size: 14px;
}
.modal-body label {
  font-size: 1rem !important;
  color: #6b6f82 !important;
  font-weight: 500 !important;
}
.modal-body a,
.modal-body p {
  font-size: 0.9rem !important;
  color: #6b6f82 !important;
  font-weight: 400 !important;
  word-break: break-all;
  padding: 0;
}
.comment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  float: left;
  font-size: 0.9rem;
  color: #6b6f82;
  font-weight: 400;
}
.comment a {
  padding: 0 4px 0 0;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.scrolling-wrapper .card {
  display: inline-block;
  margin: 10px;
}

.multi-check-box-select
  .react-dropdown-tree-select
  .dropdown
  .dropdown-trigger {
  position: relative;
  border: 0px solid #000 !important;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger {
  position: relative;
  padding: 0.375rem 0.75rem !important;
  border-radius: 12px !important;
  background-color: #ffffff;
  max-width: 250px;
  border: 1px solid #ced4da;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger:focus,
.react-dropdown-tree-select .dropdown .dropdown-trigger:focus-within,
.react-dropdown-tree-select .dropdown .dropdown-trigger:active {
  border-color: #0f203a !important;
  box-shadow: 0 0 0 0.2rem rgb(15 32 58 / 20%) !important;
}

label.float-left.mb-0.custom-label {
  width: 100%;
}

.multi-check-box-select
  .react-dropdown-tree-select
  .dropdown
  .dropdown-trigger.arrow.bottom:after {
  content: "\f078";
  vertical-align: middle;
  color: #3c3c3c;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  width: 100%;
  height: 250px;
  overflow-y: auto;
  z-index: 99 !important;
}
.infinite-scroll-component li {
  line-height: 0px;
  padding: 12px 10px;
}
.infinite-scroll-component li label {
  margin-bottom: 0px;
}



.multiSelectContainer .searchWrapper {
  max-width: 242px;
  border: solid 1px #96969696;
  min-height: 64px;
  padding: 0.5rem 1rem;
  background: #fff;
  border-radius: 11px;
}

.multi-check-box-select .optionListContainer .optionContainer li {
  color: #000;
}

span.chip {
  background: #f4f4f4f4;
  height: 38px;
  color: #009da0;
  border-radius: 11px;
}

i.custom-close  {
  color: grey;
  margin-left: 0.2rem;
}

.multi-check-box-select
  .optionListContainer
  .optionContainer
  li.highlightOption {
  background: none;
}

.multi-check-box-select .optionListContainer .optionContainer li:hover {
  background: #f4f4f4f4;
  color: #000;
}
